import type { DirectiveBinding } from 'vue';

const vTooltip = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        const options = typeof binding.value === 'string' ? { title: binding.value} : binding.value;
        const tooltip = window.bootstrap.Tooltip.getOrCreateInstance(el, options);
        if (options.tooltipRef) {
            options.tooltipRef(tooltip);
        }
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
        const options = typeof binding.value === 'string' ? { title: binding.value} : binding.value;
        const tooltip = window.bootstrap.Tooltip.getOrCreateInstance(el, options);
        if (options.tooltipRef) {
            options.tooltipRef(tooltip);
        }
        tooltip.setContent({'.tooltip-inner': options.title});
    },
    beforeUnmount(el: HTMLElement, binding: DirectiveBinding) {
        const options = typeof binding.value === 'string' ? { title: binding.value} : binding.value;
        const tooltip = window.bootstrap.Tooltip.getInstance(el);
        if (options.tooltipRef) {
            options.tooltipRef(null);
        }
        tooltip?.dispose();
    },
};

export default vTooltip;